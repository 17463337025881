import lunarnewyear from "./Images/lunarnewyear.png";
import fa24gbm from "./Images/fa24gbm.png";
import fa24bonfire from "./Images/fa24bonfire.png";
import fa24gamenight from "./Images/fa24gamenight.png";
import fa24iceskating from "./Images/fa24iceskating.png"
import fa24friendsgiving from "./Images/fa24friendsgiving.png"

export const EventsData = [
  {
    image: fa24gbm,
    title: "GBM 1",
    date: "October 10, 2024",
    description:
      "We will be hosting our first GBM of the 2024-2025 academic year! Swing by to learn more about what we do, what events we host, and how you can get involved! We will have food for everyone!",
  },
  {
    image: fa24bonfire,
    title: "Bonfire",
    date: "October 11, 2024",
    description:
      "We will be having a bonfire at La Jolla Shores on Friday (Oct 11) at 6pm!!!! We love our bonfires and seeing everyone at them so come to our first one of this school year and hang out with BURSA!!!! Meet in front of Main Gym at 5:30pm to take the bus with other members! We hope to see you there!!😋🍂🌊",
  },
  {
    image: fa24gamenight,
    title: "Game Night",
    date: "October 23, 2024",
    description:
      "Happy Week 4!!! We’ll be having a game night for our second GBM of fall quarter ‘24! Join us this Wednesday 10/23 @ 7pm in Price Centers ERC room to destress from midterms and play fun games + enjoy free food!!!!!! 🎲🎮👾🎱",
  },
  {
    image: fa24iceskating,
    title: "UTC Ice Skating",
    date: "November 16, 2024",
    description:
      "Lace up your skates and glide into a night of fun! Join us for a frosty evening on the ice at our Ice Skating Night on November 16th from 6:30 PM to 9:00 PM. Whether you're a seasoned skater or a first-timer, come enjoy the music, the wintery atmosphere, and the company of friends, as we skate under the twinkling lights.",
  },
  {
    image: fa24friendsgiving,
    title: "Friendsgiving",
    date: "November 22, 2024",
    description:
      "Join us for a Friendsgiving Feast on November 16th as we come together to celebrate friendship, food, and all the things we're grateful for. This is a potluck-style event, so bring your favorite dish—whether it’s a savory side, a mouthwatering main, or a sweet treat—to contribute to the spread. Whether you’re a kitchen pro or just love to eat, everyone is welcome!",
  },
];
